import Dropdown from "react-bootstrap/Dropdown";
import React, { useState, useEffect } from "react";
import {
  Button,
  ButtonGroup,
  Col,
  Container,
  Nav,
  Navbar,
  Row,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import "./openJobs.css";

function OpenJobs() {
  const [showMore, setShowMore] = useState(false);
  const [jobItems, setJobItems] = useState([]); 

  function decodeHTML(html) {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  }

  const truncateDescription = (description, length = 150) => {
    const decoded = decodeHTML(description);
    return decoded.length > length ? `${decoded.substring(0, length)}...` : decoded;
  };

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await fetch("https://system.neutrosys.net/career");
        const data = await response.json();

        const openPositions = data.allcontractjob; 
        const formattedJobs = openPositions.map((job) => ({
          id: job.JobPostingID,
          title: job.Title,
          location: `${job.City}, ${job.State}, ${job.Country} | ${job.Category} | ${job.JobType}`,
          disc: truncateDescription(job.DescriptionI, 150), 
          type: `${job.DatePosted} - ${job.JobStatusName}`,
        }));

        setJobItems(formattedJobs);
      } catch (error) {
        console.error("Error fetching job data:", error);
      }
    };

    fetchJobs();
  }, []);

  return (
    <div>
      <Container className="openjob-container">
        <h2>Our Open Positions</h2>
        <Row className="outer-row">
          <Col sm={10} className="outer-col">
            {/* <Navbar collapseOnSelect expand="md">
              <Container className="bgjob">
                <Navbar.Brand href="#home">All jobs</Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                  <Nav className="ms-auto">
                    <div className="div-right">
                      <Dropdown as={ButtonGroup}>
                        <Dropdown.Toggle
                          id="dropdown-custom-1"
                          className="openpos-btn"
                        >
                          Location
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="super-colors locationeg">
                          
                        </Dropdown.Menu>
                      </Dropdown>
                      <Dropdown as={ButtonGroup}>
                        <Dropdown.Toggle
                          id="dropdown-custom-1"
                          className="openpos-btn"
                        >
                          Categories
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="super-colors2">
                          
                        </Dropdown.Menu>
                      </Dropdown>
                      <Dropdown as={ButtonGroup}>
                        <Dropdown.Toggle
                          id="dropdown-custom-1"
                          className="openpos-btn"
                        >
                          Job Types
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="super-colors3">
                          {/* Add your job type checkboxes here */}
                        {/* </Dropdown.Menu>
                      </Dropdown>
                      <Button variant="primary" className="filter-btn my-2">
                        Filter */}
                      {/* </Button>
                    </div>
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar> */} 

            {jobItems.slice(0, showMore ? jobItems.length : 3).map((job) => {
              return (
                <div className="div-bggrey" key={job.id}>
                  <Row className="inner-row">
                    <Col sm={9} className="inner-col">
                      <h5>
                        <Link to={`/job/${job.id}`}>{job.title}</Link>
                      </h5>
                      <h6>{job.location}</h6>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: decodeHTML(job.disc),
                        }}
                      />
                      <p style={{ textAlign: "left", color: "green" }}>
                        {job.type}
                      </p>
                    </Col>
                    <Col sm={2} className="inner-col2">
                      <h5>
                        <Link to={`/contract/${job.id}`}>Apply</Link>
                      </h5>
                    </Col>
                  </Row>
                </div>
              );
            })}

            {jobItems.length > 3 && (
              <button
                className="more-job-btn"
                onClick={() => setShowMore(!showMore)}
              >
                {showMore ? "Less Jobs.." : "More Jobs.."}
              </button>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default OpenJobs;