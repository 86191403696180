import React, { useEffect, useState } from "react";
import "./Internaljob.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { BsArrowRightCircle } from "react-icons/bs";
import employeeimg1 from "../Careersimages/emp1.jpg";
import employeeimg2 from "../Careersimages/emp2.jpg";
import employeeimg3 from "../Careersimages/emp3.jpg";
import { Link, Outlet } from "react-router-dom"; 

const Internaljob = () => {
  const [jobs, setJobs] = useState([]);

  function decodeHTML(html) {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  }
  

  useEffect(() => {
    // const fetchJobs = async () => {
    //   try {
    //     const response = await fetch("https://system.neutrosys.net/career");
    //     const data = await response.json();
    //     setJobs(data.allInternalJobs); 
    //   } catch (error) {
    //     console.error("Error fetching job data:", error);
    //   }
    // };
    const fetchJobs = async () => {
      try {
        const response = await fetch("https://system.neutrosys.net/career");
        const data = await response.json();
        
        
        const filteredJobs = data.allInternalJobs.filter(job => {
          const datePosted = new Date(job.DatePosted);
          return datePosted > new Date("2022-12-31"); 
        });
    
        setJobs(filteredJobs); 
      } catch (error) {
        console.error("Error fetching job data:", error);
      }
    };

    fetchJobs();
  }, []);

  return (
    <>
      <div className="internal-maindiv">
        <h2>Work as an Employee</h2>

        <div className="internal-div">
          <Row className="emp-row">
            <Col sm={5} className="emp-col1 emp-topm">
              <img src={employeeimg1} alt="employee1" />
            </Col>
            <Col sm={6} className="emp-col2">
              <h5>Limitless opportunities</h5>
              <p>
                Our diverse workplace culture values equity and offers ample opportunities
                <br /> for growth and career advancement, with a positive work-life balance
                <br /> and ongoing training.
              </p>
            </Col>
          </Row>
          <Row className="emp-row">
            <Col sm={6} className="emp-col2 col-text2">
              <h5>Leadership roles</h5>
              <p>
                We prioritize leadership development and empower our employees to excel <br />
                in their roles, believing that it benefits their professional growth and
                <br /> contributes to our organization's success.
              </p>
            </Col>
            <Col sm={5} className="emp-col1">
              <img src={employeeimg2} alt="employee2" />
            </Col>
          </Row>
          <Row className="emp-row">
            <Col sm={5} className="emp-col1 col-buttom">
              <img src={employeeimg3} alt="employee3" />
            </Col>
            <Col sm={6} className="emp-col2">
              <h5>Support above and beyond</h5>
              <p>
                We support our employees through mentorship, training, wellness initiatives,
                <br /> and an inclusive culture, resulting in improved job performance and
                <br /> readiness for new responsibilities.
              </p>
            </Col>
          </Row>
        </div>
      </div>

      <h2 className="openposition-heading">Our Open Positions</h2>
      <div className="scrollbar-outerdiv">
        <input type="checkbox" id="readmore" style={{ display: "none" }} />
        <div className="scrollbar-internaljob">
          <Row className="openposition-row">
            {jobs.map((job) => (
             <Col 
             lg={3} 
             className="openposition-col mb-lg-3" 
             key={job.JobPostingID} 
             
           >
                <Row className="heading-rowbg">
                  <Col className="text-col">
                    <h6>{job.Category}</h6>
                    {/* <h4>{job.Title}</h4> */}
                    <h4>{decodeHTML(job.Title)}</h4>
                    <Row className="footer-row">
                      <Col sm={9} className="loc-text">
                        <p>{`${job.City}, ${job.State}, ${job.Country}`}</p>
                      </Col>
                      <Col sm={2} className="icon-text">
                        <Link to={`/job/${job.JobPostingID}`}>
                       <p>
                      <BsArrowRightCircle />
                    </p></Link>
                        <Outlet />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            ))}
          </Row>
        </div>

        <label htmlFor="readmore" className="internal-viewmore"></label>
      </div>
    </>
  );
};

export default Internaljob;