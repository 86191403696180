import React, { useMemo, useRef, useState,useEffect } from "react";
import "./Detail.css";
import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Controller, useForm } from "react-hook-form";
import { FaWhatsapp } from 'react-icons/fa';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useParams } from "react-router-dom";


import { MdEmail } from "react-icons/md";
import { Box, Typography } from '@mui/material';
import { HiPrinter } from "react-icons/hi";
import { MdFileCopy } from "react-icons/md";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { Country, State, City } from "country-state-city";
import { FileUploader } from "react-drag-drop-files";
// import { ICountry } from "country-state-city";
import Button from "react-bootstrap/Button";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Modal } from "react-bootstrap";
import { ImLocation } from "react-icons/im";
import { FaSuitcase } from "react-icons/fa";
import { FaRegClock } from "react-icons/fa";
import { ImFacebook2 } from "react-icons/im";
import { ImLinkedin } from "react-icons/im";
import { FaTwitterSquare } from "react-icons/fa";
import ReactToPrint from "react-to-print";
import { CopyToClipboard } from "react-copy-to-clipboard";
import swal from "sweetalert";
import { useDropzone } from "react-dropzone";
import { TextField } from "@mui/material";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from "react-country-region-selector";
import UploadFiles from "./UploadFiles";
import Select from "react-select";

const JobDetails = (props) => {
  const [mobile, setMobile] = useState("+977");
  const [show, setShow] = useState(false);
  const [base64DataCV, setBase64DataCV] = useState("");
  const [base64DataResume, setBase64DataResume] = useState("");
  // const [coverLetterName, setCoverLetterName] = React.useState('');
  // const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadedCoverLetter, setUploadedCoverLetter] = useState(null);
  const [uploadedResume, setUploadedResume] = useState(null);
  const [isCoverLetterUploaded, setIsCoverLetterUploaded] = useState(false);
  const [isResumeUploaded, setIsResumeUploaded] = useState(false);
  const [job, setJob] = useState(null);

  const navigate = useNavigate();
  const [loadingSubmit, setLoadingSubmit] = useState(false); 
  const Componentref = useRef();
  const { jobId } = useParams();

  const handleClose = () => {
    setShow(false);
    reset(); 
  };
  const handleShow = () => setShow(true);

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();
  const [resumeUploaded, setResumeUploaded] = useState(false); // Add this line
  const [resumeError, setResumeError] = useState(""); // Add this line for error message
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    if (!resumeUploaded) {
      setResumeError("Resume is mandatory."); // Set error message
      return; // Prevent form submission
  }
    setLoadingSubmit(true); // Start loading
    try {
      console.log(data);
      data.coverletter = base64DataCV;
      data.resume = base64DataResume;
  
      // Fetch the base URL from the environment variable
      const baseURL = process.env.REACT_APP_API_URL;
      console.log("Base URL:", baseURL);
  
      // Send the first email
      const response = await axios.post(
        `${baseURL}/api/mailsend/confirmationafterjobapplytocandidate`,
        data
      );
      console.log("Candidate confirmation response:", response);
  
    
      const responseafterhost = await axios.post(
        `${baseURL}/api/mailsend/applytojobmailreceivedbyhost`,
        data
      );
      console.log("Host notification response:", responseafterhost);
  
     
      reset();
      setBase64DataCV(""); // Clear cover letter
      setBase64DataResume(""); // Clear resume
      setBase64DataCV(""); // Clear cover letter
      setBase64DataResume(""); // Clear resume
      setIsCoverLetterUploaded(false); // Reset upload status
      setIsResumeUploaded(false); // Reset upload status
      setUploadedCoverLetter(null); // Clear uploaded cover letter name
      setUploadedResume(null); // Clear uploaded resume name
     setResumeUploaded(false); // Reset resume uploaded state
      setResumeError(""); // Clear any previous error message
  
      toast.success("your form has been submitted");
      
    } catch (error) {
     
      console.error("Error during form submission:", error);
      swal("Something went wrong. Please try again later.", {
        icon: "error",
      });
    } finally {
      
      setLoadingSubmit(false);
    }
  };
  useEffect(() => {
    const fetchJob = async () => {
      try {
        const response = await axios.get("https://system.neutrosys.net/career");
        const data = response.data.allInternalJobs.find((job) => job.JobPostingID === parseInt(jobId));
        setJob(data); // Set the job details
      } catch (error) {
        console.error("Error fetching job data:", error);
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };
  
    fetchJob();
  }, [jobId]);
  const onCoverLetterDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setBase64DataCV(reader.result);
        setIsCoverLetterUploaded(true);
        setUploadedCoverLetter(file.name); 
      };
      reader.readAsDataURL(file);
    }
  };
  
  const onResumeDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // setBase64DataResume(reader.result);
        // setResumeUploaded(true); // Set resume uploaded state to true
        // setResumeError(""); // Clear any previous error message
        setBase64DataResume(reader.result);
        setIsResumeUploaded(true);
        setUploadedResume(file.name); // Set the uploaded file name
        setResumeUploaded(true); // Set resume uploaded state to true
        setResumeError(""); // Clear any previous error message
        
      };
      reader.readAsDataURL(file);
    }
  };
  const { getRootProps: getCoverLetterRootProps, getInputProps: getCoverLetterInputProps } = useDropzone({
    onDrop: onCoverLetterDrop,
    accept: 'image/*,application/pdf', // Acceptable file types
  });

  // Using useDropzone for Resume
  const { getRootProps: getResumeRootProps, getInputProps: getResumeInputProps } = useDropzone({
    onDrop: onResumeDrop,
    accept: 'image/*,application/pdf', // Acceptable file types
  });

  const truncateFileName = (fileName) => {
    if (fileName.length > 15) {
      return fileName.substring(0, 15) + '...'; 
    }
    return fileName;
  };
    
  

  const handleCVFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setBase64DataCV(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    const fetchJob = async () => {
      try {
        const response = await axios.get("https://system.neutrosys.net/career");
        const data = response.data.allInternalJobs.find((job) => job.JobPostingID === parseInt(jobId));
        setJob(data); // Set the job details
      } catch (error) {
        console.error("Error fetching job data:", error);
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };
  
    fetchJob();
  }, [jobId]);

  const handleResumeFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setBase64DataResume(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedRegion, setSelectedRegion] = useState("");

  const handleCountryChange = (val) => {
    setSelectedCountry(val);
    setSelectedRegion(""); 
  };

  const handleRegionChange = (val) => {
    setSelectedRegion(val);
  };

  function stripHtmlTags(htmlString) {
    return htmlString.replace(/<[^>]+>/g, ''); 
  }

  function decodeHtml(html) {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  }
  
//   if (loading) return <div>Loading job details...</div>; // Show loading state
//   if (!job) return <div>No job details found.</div>; // Handle case where no job details are found

  return (
    <div className="main-divbg">
       <ToastContainer position="top-center" />
      <Container className="details-container">
        <Row className="back-row">
          <Col className="back-col">
            <button className="back-btn" onClick={() => navigate(-1)}>
              Back
            </button>
          </Col>
        </Row>

        <Row className="top-row">
          <Col sm={9} className="jobs-top-col">
            <h2>{job ? job.Title : 'Loading...'}</h2>
          </Col>

          <Col sm={3} className="jobs-top -col2 ">
            <button onClick={handleShow} className="jobs-top-btn">
              Apply Now
            </button>
          </Col>
        </Row>

        <Modal
          show={show}
          onHide={handleClose}
          size="lg"
          className="jobdetails-modal"
        >
          <Modal.Header closeButton className="jobs-modal-header">
            <Modal.Title className="top-form">
              ENTER YOUR INFORMATION
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="jobdetails-body">
            <form
              className="form details-form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Row className="mt-1">
                <Col lg={6} md={12} sm={12} className="resume-form-col fname-width">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="First Name"
                    className="input-field"
                    style={{ color: errors.name ? 'red' : 'inherit' }}
                  >
                    <Form.Control
                      type="text"
                      placeholder="First Name"
                      {...register("name", {
                        required: "true",
                        minLength: { value: 3, message: "Minimum length is 3" },
                      })}
                      isInvalid={!!errors?.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {/* {errors.name?.message} */}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
                <Col lg={6} md={12} sm={12} className="resume-form-col mname-width">
                  <FloatingLabel
                    controlId="floatingPassword"
                    label="Middle Name (If applicable)"
                    className="input-field"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Middle Name (If applicable)"
                      {...register("middlename")}
                    />
                  </FloatingLabel>
                </Col>
              </Row>
              <Row>
                <Col lg={6} md={12} sm={12} className="resume-form-col">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Last Name"
                    className="input-field p-0"
                    style={{ color: errors.name ? 'red' : 'inherit' }}
                  >
                    <Form.Control
                      type="text"
                      placeholder="Last Name"
                      {...register("lastname", { required: "Required" })}
                      isInvalid={!!errors?.lastname}
                    />
                    <Form.Control.Feedback type="invalid">
                      {/* {errors.lastname?.message} */}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>

                <Col lg={6} md={12} sm={12} className="resume-form-col">
                  <Form.Select
                    aria-label="Default select example"
                    className="input-field gender-padding"
                    style={{ color: errors.name ? 'red' : 'inherit' }}
                    {...register("gender", { required: "Required" })}
                    isInvalid={!!errors?.gender}
                  >
                    <option value="">Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="unspecified">Unspecified</option>
                    <option value="undisclosed">Undisclosed</option>
                    <option value="other">Other</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {/* {errors.gender?.message} */}
                  </Form.Control.Feedback>
                </Col>
              </Row>

              <Row>
                <Col lg={6} md={12} sm={12} className="resume-form-col cc-width-div">
                  <FloatingLabel
                    controlId="floatingPassword"
                    label="Country Code"
                    className="input-field"
                    style={{ color: errors.name ? 'red' : 'inherit' }}
                  >
                    <Form.Control
                      type="number"
                      name="country_code"
                      placeholder="Nepal +977"
                      {...register("country_code", { required: "Required" })}
                      isInvalid={!!errors?.country_code}
                    />
                    <Form.Control.Feedback type="invalid">
                      {/* {errors.country_code?.message} */}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>

                <Col lg={6} md={12} sm={12} className="resume-form-col p-width-div">
                  <FloatingLabel
                    controlId="floatingPassword"
                    label="Phone Number"
                    className="input-field"
                    style={{ color: errors.name ? 'red' : 'inherit' }}
                  >
                    <Form.Control
                      type="number"
                      name="phone"
                      placeholder="Phone Number"
                      {...register("phone", { required: "Required" })}
                      isInvalid={!!errors?.phone}
                    />
                    <Form.Control.Feedback type="invalid">
                      {/* {errors.phone?.message} */}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
              </Row>

              <Row>
                <Col lg={6} md={12} sm={12} className="resume-form-col mb-2 country-width">
                  <Controller
                    name ="countryname"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <CountryDropdown
                        {...field}
                        className="input-field select"
                        // style={{ color: "#000000d4", height: "3.7rem" }}
                        style={{
                          color: errors.name ? 'red' : '#000000d4', 
                          height: "3.7rem"
                        }}
                        onChange={(val) => {
                          field.onChange(val);
                          handleCountryChange(val);
                        }}
                      />
                    )}
                  />
                </Col>

                <Col lg={6} md={12} sm={12} className="resume-form-col mb-2 region-width">
                  <Controller
                    name="regionname"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <RegionDropdown
                        {...field}
                        className="input-field select"
                        country={selectedCountry}
                        value={selectedRegion}
                        // style={{ color: "#000000d4", height: "3.7rem" }}
                        style={{
                          color: errors.name ? 'red' : '#000000d4', // Conditional color
                          height: "3.7rem"
                        }}
                        blankOptionLabel="Select Region"
                        onChange={(val) => {
                          field.onChange(val);
                          handleRegionChange(val);
                        }}
                      />
                    )}
                  />
                </Col>
              </Row>

              <Row>
                <Col lg={6} md={12} sm={12} className="resume-form-col">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="City"
                    className="input-field"
                    style={{ color: errors.name ? 'red' : 'inherit' }}
                  >
                    <Form.Control
                      type="text"
                      {...register("city", { required: "Required field" })}
                      placeholder="City"
                      isInvalid={!!errors?.city}
                    />
                    <Form.Control.Feedback type="invalid">
                      {/* {errors.city?.message} */}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>

                <Col lg={6} md={12} sm={12} className="resume-form-col">
                  <FloatingLabel
                    controlId="floatingPassword"
                    label="Current Address"
                    className="input-field"
                    style={{ color: errors.name ? 'red' : 'inherit' }}
                  >
                    <Form.Control
                      type="text"
                      {...register("currentaddress", { required: "Required field" })}
                      placeholder="Current Address"
                      isInvalid={!!errors?.currentaddress}
                    />
                    <Form.Control.Feedback type="invalid">
                      {/* {errors.currentaddress?.message} */}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
              </Row>

              <Row>
                <Col lg={6} md={12} sm={12} className="resume-form-col email-width">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Email"
                    className="input-field"
                    style={{ color: errors.name ? 'red' : 'inherit' }}
                  >
                    <Form.Control
                      type="email"
                      placeholder="name@example.com"
                      {...register("email", {
                        required: "Required field",
                        pattern: {
                          value: /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i,
                          message: "Invalid email address",
                        },
                      })}
                      isInvalid={!!errors?.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {/* {errors.email?.message} */}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>

                <Col lg={6} md={12} sm={12} className="resume-form-col salary-width">
                  <FloatingLabel
                    controlId="floatingPassword"
                    label="Expected Salary (Optional)"
                    className="input-field number-arrow label-light"
                  >
                    <Form.Control
                      type="number"
                      {...register("expectedsalary")}
                    />
                  </FloatingLabel>
                </Col>
              </Row>

              <Row>
                <Col lg={6} md={12} sm={12} className="resume-form-col level-width">
                  <Controller
                    name="jobappliedfor"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <Form.Select
                        aria-label="Default select example"
                        className="mb-3 input-field"
                        style={{ color: errors.name ? 'red' : 'inherit' }}
                        {...field}
                      >
                        <option value="">Applying for Seniority Level of</option>
                        <option value="Not Applicable">Not Applicable</option>
                        <option value="Internship">Internship</option>
                        <option value="Entry Level">Entry Level</option>
                        <option value="Associate(Junior)">Associate(Junior)</option>
                        <option value="Mid Level">Mid Level</option>
                        <option value="Senior">Senior</option>
                        <option value="Director">Director</option>
                        <option value="Executive">Executive</option>
                      </Form.Select>
                    )}
                  />
                </Col>

               
<Col lg={6} md={12} sm={12} className="resume-form-col upload-section">
  <Box
    sx={{
      border: '2px dashed rgba(128, 128, 128, 0.521)',
      borderRadius: '8px',
      padding: '10px 15px',
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
     
      transition: 'background-color 0.3s, border-color 0.3s',
      cursor: 'pointer',
      position: 'relative', 
    
    }}
    {...getCoverLetterRootProps()}
    onClick={() => document.getElementById('inputCoverLetter').click()}
  >
    <input {...getCoverLetterInputProps()} id="inputCoverLetter" style={{ display: 'none' }} />
    {isCoverLetterUploaded && (
      <span
        onClick={(e) => {
          e.stopPropagation();
          setBase64DataCV('');
          setIsCoverLetterUploaded(false);
          setUploadedCoverLetter(null);
          document.getElementById('inputCoverLetter').value = null;
        }}
        style={{
          position: 'absolute',
          top: '-10px', // Position above the border
          right: '-10px', // Position outside the border
          backgroundColor: '#ff6666',
          borderRadius: '50%',
          width: '20px',
          height: '20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'white',
          fontSize: '12px',
          cursor: 'pointer',
          boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)',
        }}
      >
        &times; {/* Cross Icon */}
      </span>
    )}
    <Button
      className="coverbutton"
      variant="contained"
      sx={{
        backgroundColor: '#3b6da7',
        color: 'white',
        textTransform: 'none',
        fontWeight: 'bold',
        padding: '8px 20px',
        borderRadius: '5px',
        fontSize: '14px',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        // '&:hover': {
        //   backgroundColor: '#2a5d8b',
        // },
      }}
    >
      {isCoverLetterUploaded ? 'Uploaded' : 'Upload Cover Letter'}
      {isCoverLetterUploaded && (
        <CheckCircleIcon
          sx={{
            fontSize: '20px',
            color: 'green',
            marginLeft: '8px',
          }}
        />
      )}
    </Button>
    <Typography
      variant="body2"
      color="textSecondary"
      sx={{ fontSize: '14px', opacity: 0.5 }}
    >
      {isCoverLetterUploaded ? truncateFileName(uploadedCoverLetter) : 'or drag them'}
    </Typography>
  </Box>
</Col>


              </Row>

              <Row className="reverse-col">
                <Col lg={6} md={12} sm={12} className="resume-form-col msgtxt-width">
                  <FloatingLabel
                    controlId="floatingTextarea2"
                    label="Message (Optional)"
                    className="input-field message-padding label-light"
                  >
                    <Form.Control
                      as="textarea"
                      {...register("message")}
                      placeholder="Leave a comment here"
                      style={{ height: "100px" }}
                    />
                  </FloatingLabel>
                </Col>

               
<Col lg={6} md={12} sm={12} className="resume-form-col">
  <Box
    sx={{
      border: '2px dashed #ccc',
      borderRadius: '8px',
      padding: '10px 15px',
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      textAlign: 'center',
      // backgroundColor: isResumeUploaded ? '#e3f2fd' : '#f9f9f9',
      transition: 'background-color 0.3s, border-color 0.3s',
      cursor: 'pointer',
      position: 'relative', 
      // '&:hover': {
      //   borderColor: '#3b6da7',
      //   backgroundColor: '#f0f8ff',
      // },
    }}
    {...getResumeRootProps()}
    onClick={() => document.getElementById('inputResume').click()}
  >
    <input {...getResumeInputProps()} id="inputResume" style={{ display: 'none' }} />
    <Button
      className="resumebutton"
      variant="contained"
      sx={{
        backgroundColor: '#6c63ff', // Purple background
        color: '#fff', // White text
        textTransform: 'none', // Normal text case
        fontWeight: 'bold',
        padding: '8px 20px', // Padding for compact button
        borderRadius: '5px', // Slightly rounded corners
        fontSize: '14px', // Adjusted font size
        // '&:hover': {
        //   backgroundColor: '#574fcf', // Darker purple on hover
        // },
      }}
    >
      {isResumeUploaded ? 'Uploaded' : 'Upload Resume/CV'}
      {isResumeUploaded && (
        <CheckCircleIcon
          sx={{
            fontSize: '20px',
            color: 'green',
            marginLeft: '8px',
          }}
        />
      )}
    </Button>
    <Typography
  variant="body2"
  color="textSecondary"
  className="dark-text"
  sx={{ fontSize: '14px',  }}
  >
      {isResumeUploaded ? truncateFileName(uploadedResume) : 'or drag them'}
    </Typography>
    {isResumeUploaded && (
      <span
        onClick={(e) => {
          e.stopPropagation();
          setBase64DataResume('');
          setIsResumeUploaded(false);
          setUploadedResume(null);
          document.getElementById('inputResume').value = null;

        }}
        style={{
          position: 'absolute',
          top: '-10px', 
          right: '-10px', 
          backgroundColor: '#ff6666',
          borderRadius: '50%',
          width: '20px',
          height: '20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'white',
          fontSize: '12px',
          cursor: 'pointer',
          boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)',
        }}
      >
        &times; {/* Cross Icon */}
      </span>
    )}
  </Box>
  {resumeError && (
    <Typography
      variant="caption"
      color="error"
      sx={{ display: 'block', marginTop: '8px' }}
    >
      {resumeError}
    </Typography>
  )}
</Col>


              </Row>

              <Row>
                <Col lg={9} md={9} sm={12} className="check-container-col">
                  <div className="check-container">
                    <input type="checkbox" name="check" id="applycheck" required />
                    <p className="check">
                      By checking this box, I certify that the information submitted in this application is true and correct to the best of my knowledge.
                    </p>
                    
                  </div>
                </Col>

                <Col lg={3} md={3} sm={12} className="submit-btn-col">
                  {/* <Button id="submit-btn" type="submit">
                    Submit
                  </Button> */}
                  <Button
              style={{
                display: "block",
                margin: "auto",
                width: "7rem",
                color: "white",
                marginTop:"10px",
                backgroundColor: "#3b6da7",
              }}
              type="submit"
              variant="contained"
              disabled={loadingSubmit} // Disable button when loading
            >
              {loadingSubmit ? (
                <div className="spinner-border text-light" role="status" style={{ fontSize: "1.2rem" }}>
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                "Submit"
              )}
            </Button>

                </Col>
              </Row>
            </form>
          </Modal.Body>
        </Modal>

        <Row className="jobs-outer-row">
          <Col sm={4} className="outer-col">
            <Row className="second-row">
            <Col sm={12} className="left-col">
      <h3>Job Overview</h3>
      <p>
        Req #: <span>{job ? job.JobPostingID : 'N/A'}</span> {/* Dynamic Job ID */}
      </p>

      <div className="section section-first">
        <i className="fa-solid">
          <ImLocation />
        </i>
        <div className="inner-section inner-loc">
          <h1>Location:</h1>
          <span>{job ? `${job.City}, ${job.State}, ${job.Country}` : 'N/A'}</span> {/* Dynamic Location */}
        </div>
      </div>

      <div className="section">
        <i className="fa-solid">
          <FaSuitcase />
        </i>
        <div className="inner-section">
          <h1>Job Category:</h1>
          <span>{job ? job.Category : 'N/A'}</span> {/* Dynamic Job Category */}
        </div>
      </div>

      <div className="section">
        <i className="fa-solid">
          <FaRegClock />
        </i>
        <div className="inner-section">
          <h1>Date Posted:</h1>
          <span>{job ? job.DatePosted : 'N/A'}</span> {/* Dynamic Date Posted */}
        </div>
      </div>
    </Col>
            </Row>

            <Row className="leftbottom-row">
              <Col sm={12} className="leftbottom-col">
                <h4>Share this job</h4>
                <Row className="share-row">
                  <Col sm={6} className="share-col ">
                    <div className="socials">
                      {/* <a
                        href="https://www.facebook.com/Neutrosys"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fa-brands">
                          <ImFacebook2 />
                        </i>
                      </a> */}
                      <a
                    href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent('https://neutrosys.net/careers/jobSection')}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa-brands">
                      <ImFacebook2 />
                    </i>
                   </a>

 {/* <a
                        href="https://www.linkedin.com/company/neutrosys/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fa-brands">
                          <ImLinkedin />
                        </i>
                      </a> */}
                      <a
                    href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent('https://neutrosys.net/careers/jobSection')}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa-brands">
                      <ImLinkedin />
                    </i>
                  </a>

                      {/* <a href="/#">
                        <i className="fa-brands twitticon">
                          <FaTwitterSquare />
                        </i>
                      </a> */}
                      {/* <a href="/#">
                        <i className="fa-brands twitticon">
                          <FaWhatsapp />
                        </i>
                      </a> */}
                      <a
  href={`https://api.whatsapp.com/send?text=${encodeURIComponent('Check this out: https://neutrosys.net/careers/jobSection')}`}
  target="_blank"
  rel="noopener noreferrer"
>
  <i className="fa-brands twitticon">
    <FaWhatsapp />
  </i>
</a>
                    </div>
                  </Col>
                  <Col sm={6} className="share-col2">
                    <div className="vertical-div">
                      <p>
                        <MdEmail />
                        &nbsp;&nbsp;
                        <span
                          onClick={() => {
                            window.location.href =
                              "mailto:neutroline@gmail.com";
                          }}
                        >
                          Email this job
                        </span>
                      </p>
                      <p>
                        <HiPrinter />
                        <ReactToPrint
                          trigger={() => (
                            <span style={{ marginLeft: ".7rem" }}>Print </span>
                          )}
                          content={() => {
                            console.log(Componentref.current); // Debugging line
                            return Componentref.current;
                          }}
                        />
                      </p>
                      <p>
                        <MdFileCopy />
                        &nbsp;&nbsp;
                        <span>
                          {/* <CopyToClipboard
                            text="https://neutrosys.net:3000/Details/sales#"
                            onCopy={() =>
                              swal({
                                title: "Good job!",
                                text: "Job link has been copied!",
                                icon: "success",
                                button: "close",
                              })
                            }
                          >
                            <span>Copy</span>
                          </CopyToClipboard> */}
                           <CopyToClipboard
  text="https://neutrosys.net:3000/Details/sales#"
  onCopy={() => {
    
    swal({
      // title: "Good job!",
      text: "Job link has been copied!",
      icon: "success",
      button: false, 
      className: "custom-swal",
    });

    
    setTimeout(() => {
      swal.close(); 
    }, 1000); 
  }}
>
  <span>Copy</span>
</CopyToClipboard> 

                        </span>
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>

          <Col sm={8} className="right-col" ref={Componentref}>
  <h3>Job Description</h3>
  {job && job.Description ? (
    <div
      dangerouslySetInnerHTML={{
        __html: decodeHtml(job.Description),
      }}
    />
  ) : (
    <p>No job description available.</p>
  )}

  <h6>Responsibilities</h6>
  {job && job.Responsibilities ? (
    <div
      dangerouslySetInnerHTML={{
        __html: decodeHtml(job.Responsibilities),
      }}
    />
  ) : (
    <p>No responsibilities listed.</p>
  )}

  <h6>Qualifications:</h6>
  {job && job.Qualification ? (
    <div
      dangerouslySetInnerHTML={{
        __html: decodeHtml(job.Qualification),
      }}
    />
  ) : (
    <p>No qualifications listed.</p>
  )}

  <h6>Why join us?</h6>
  {job && job.AdditionalInfo ? (
    <div
      dangerouslySetInnerHTML={{
        __html: decodeHtml(job.AdditionalInfo),
      }}
    />
  ) : (
    <p>No additional information available.</p>
    
  )}
  {/* <ul>
    <li>Competitive Base Salary</li>
    <li>Competitive Commission Structure</li>
    <li>Bonus</li>
    <li>PTO</li>
    <li>Flexible Working Hours</li>
  </ul> */}

  <p className="italic">
    Neutrosys Inc is an EEO Employer providing IT solutions in United States, Asia, and Europe.
  </p>
  <button className="apply-btn" onClick={handleShow}>
    Apply Now
  </button>
</Col>

        </Row>
      </Container>
    </div>
  );
};

export default JobDetails;